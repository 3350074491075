import * as React from "react";
import { Link } from "gatsby";

import SEO from "../components/SEO";
import Nav from "../components/Nav";
import Button from "../components/Button";

export default function IndexPage() {
  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="text-white bg-gradient-to-br from-primary-600 to-primary-400 h-screen">
        <SEO title="Page Not Found" />
        <header>
          <Nav />
        </header>
        <main className="mx-4 lg:mx-28 pt-14">
          <div>
            <div className="flex space-x-4 mb-10">
              <div className="text-3xl sm:text-4xl lg:text-6xl py-4 font-bold">
                404
              </div>
              <div className="space-y-4 border-l-4 border-white pl-4 py-4">
                <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold capitalize">
                  Page Not Found
                </h1>
                <p className="lg:text-lg">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
            </div>
            <Link to="/">
              <Button dark={true}>Go Home</Button>
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
}
